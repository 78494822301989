* {
    font-family: "Bricolage Grotesque"
}

.center {
    text-align: center;
}

.game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    /* height: 100%; */
    border-radius: 20px;
    border: outset 4px rgba(0, 0, 0, 0.5);
    padding: 10px;
    width: 95vw;
    height: 92vh;
    margin: 2vh;
    overflow: scroll;
}

.signin {
    margin: 10px;
}

.green {
    color: green;
}

.lives {
    font-size: 1.5rem;
    margin-bottom: 6px;
    margin-top: 10px;
}

.rules-btn {
    border-radius: 5px;
    height: 50px;
    width: 100px;
    border: 2px solid white;
    background-color: #1ed760;
    margin: 4px;
    font-size: 2.5vh;
    /* font-weight: bold; */
    color: black;
}

.inline {
    display: flex;
    flex-direction: row;
    gap: 10px
}
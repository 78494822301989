/* .dropdown-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
} */

.dropdown {
    background-color: #1ed760;
    border: 2px solid green;
    color: white;
    padding: 12px;
    width: 30vh;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    /* font-size: 1.3rem */
}
html {
    /* background-color: black; */
    background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
    color: white;
    /* height: 100vh; */
}

.home {
    display: flexbox;
    width: 95vw;
    height: 92vh;
    border: outset 4px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 1);
    margin: 2vh;
    justify-items: center;
    align-self: center;
    padding: 10px;
    border-radius: 20px;
    overflow: scroll;
}

/* Header Styling */
.welcome-header {
    text-align: center;
}

.musiqle-header {
    font-size: 50px;
    font-style: sans-serif;
    text-align: center;
    color: #1ed760;
}

/* Link Styling */
.link-container {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    border: black, solid, 3px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0;
}

.link-container li {
    display: inline;
}

.signin-btn {
    border-radius: 5px;
    height: 50px;
    width: 145px;
    border: 2px solid white;
    background-color: #1ed760;
    margin: 4px;
    font-size: 2.5vh;
    font-weight: bold;
    color: black;
}

.all-stats-btn {
    border-radius: 5px;
    height: 50px;
    width: 150px;
    border: 2px solid #1db954;
    background-color: white;
    margin: 4px;
    font-size: 2vh;
    font-weight: bold;
}

.sign-in {
    padding: 16px;
}

a:link {
    text-decoration: none;
    color: black;
}

a:visited {
    color: #191414;
}

.user-header {
    font-size: 18px;
    margin-bottom: 2vh;
}

.musiqle-logo {
    width: auto;
    margin-top: 50px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.user-login-page {
    text-align: center;
}

.user-login-btn {
    border-radius: 5px;
    height: 18px;
    width: 52px;
    border: 2px solid #1db954;
    background-color: white;
    margin: 4px;
    font-size: 8.5px;
    font-weight: 550;
}

.link-list {
    padding: 10vh;
    font-size: 40px;
    color: #191414;
    background-color: #1ed760;
    margin: 4vh;
    border-radius: 10px;
    border: 5px solid #1db954
}

.right {
    /* display: flex;
    flex-direction: row; */
    right: 20px;
    top: 20px;
    /* margin: 20px; */
    margin-right: 20px;
    gap: 20px;
    position: fixed;
}

.left {
    /* display: flex;
    flex-direction: row; */
    left: 20px;
    top: 20px;
    /* margin: 20px; */
    margin-left: 20px;
    gap: 20px;
    position: fixed;
}

.link-list:hover {
    border-width: 5px;
    background: linear-gradient(315deg, #63d471 0%, #FFFFFF 63%);
    font-weight: bold;
}
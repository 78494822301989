.lyric {
    margin-top: 1vh;
    justify-content: center;
    text-align: center;
    padding: 2vh;
    padding-top: 0;
    font-size: 17px;
    letter-spacing: 0.7px;
}

.song-size {
    height: 240px;
    width: 440px;
}
.wide {
    width: 60vw;
    font-size: 1.3rem
}

.right-align {
    /* display: flex; */
    /* flex-direction: column; */
    justify-content: right;
}

.left-align {
    /* display: flex; */
    justify-content: left;
}

.flex {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    padding-bottom: 2px;
}
.nav-link {
    border: 2px #1db954 solid;
    color: white;
    background-color: #1ed760;
    padding: 10px;
    border-radius: 10px;
    min-height: 3.3vh;
}

.nav-container {
    display: flex;
    justify-content: space-around;
    height: 70px;
    width: 80vw;
    max-width: 100vw;
    align-items: center;
    
}

.nav-circular {
    border-radius: 10px;
    /* height: 50px; */
    width: 75px;
    border: 2px solid #1db954;
    background-color: white;
    margin: 4px;
    padding: 12px;

}
.blur8 {
    filter: blur(8px)
}

.blur6 {
    filter: blur(6px)
}

.blur4 {
    filter: blur(4px)
}

.blur2 {
    filter: blur(2px)
}

.blur0 {
    filter: blur(0px)
}

.size {
    max-height: 250px;
    max-width: 250px
}

.image {
    margin-bottom: 20px;
    border: 2px solid white;
}

input {
    border: 2px solid #1db954;
    background-color: #1ed760;
    width: 400px;
    color: white;
    border-radius: 2px;
    height: 50px;
    font-size: larger;
}

input::placeholder {
    color: white;
    padding: 10px;
}

.circular {
    border-radius: 5px;
    height: 50px;
    width: 75px;
    border: 2px solid #1db954;
    background-color: white;
    margin: 4px;
}


form {
    display: flex;
    align-items: center;
    gap: 10px;
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif */
}

form button {
    font-size: larger;
}
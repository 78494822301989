.page-arrow {
    position: fixed;
    top: 70vh;
    align-content: center;
}

.page-arrow:hover {
    color: #1db954;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
}

.right-arrow {
    right: 20vw;
}

.center {
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 4vh;
}
.popup-input {
    width: 50%;
    height: 20%;
    background-color: white;
    color: black;
}

.user-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.header {
    color: white;
    font-size: 35px;
    letter-spacing: 3px;
    margin-top: 0;
}

h4 {
    margin: 0;
    color: #1ed760;
    font-size: 3vh;
    letter-spacing: 0.3vh;
}

input[type=text]:focus {
    outline: #1ed760
}

h6 {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.1vh;
}

.enter-submit-btn {
    border-radius: 10px;
    /* background-color: white; */
    outline: none;
    border: 2px solid white;
    padding: 15px;
    background-color: #1ed760;
    border-radius: 5px;
    margin-top: 3vh;
}

button:hover {
    border: 2px solid #323232;
    background-color: #1ed760;
}


.modal_content {
    background-color: black;
    color: white;
    position: fixed;
    top: 18%;
    left: 18%;
    width: 40%;
    padding: 20px;
    margin-left: auto;
    border-radius: 5px;
    border: 2px solid #1db954;
    height: 50vh;
    width: 60%;
    justify-content: center;
    overflow: scroll;
    z-index: 1;
}

.close {
    color: white;
    size: 100px;
    float: right;
}

.close:hover {
    color: #1db954;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
}

/* Stats PopUp CSS Specific  */
.stats-header {
    text-align: center;
    color: #1db954;
    font-size: 5vh;
    margin-top: 0;
}

h5 {
    font-size: 3vh;
    margin: 2.8vh;
}